import {
  BranchesOutlined,
  CloseCircleOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';
import { IssueState } from '@octokit/graphql-schema';
import React from 'react';
import * as Colors from '@ant-design/colors';

const config = {
  OPEN: <PullRequestOutlined style={{ color: Colors.green[5] }} />,
  CLOSED: <CloseCircleOutlined style={{ color: Colors.grey[0] }} />,
  MERGED: (
    <BranchesOutlined
      style={{
        color: Colors.purple[4],
        transform: 'rotate(180deg) scaleX(-1)',
      }}
    />
  ),
};

export default function IssueTag({ state }: { state: IssueState }) {
  return config[state];
}
