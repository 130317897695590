import { useCallback, useEffect, useState } from 'react';

const useAuth = () => {
  const [isAuthed, setIsAuthed] = useState(false);

  const fetchIsAuthed = useCallback(async () => {
    const hasAccess = await localStorage.getItem('HAS_ACCESS');

    setIsAuthed(!!hasAccess);
  }, []);

  useEffect(() => {
    fetchIsAuthed();
  }, [fetchIsAuthed]);

  return isAuthed;
};

export default useAuth;
