import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

const SELECTED_USER = 'UserPicker:selectedUser';

export default function UserPicker({
  options,
  onSelect,
}: {
  options?: { value: string; label: string }[];
  onSelect: (userLogin: string) => void;
}) {
  const sorted = options?.sort((a, b) => a.value.localeCompare(b.value));
  const [selected, setSelected] = useState<string>(
    JSON.parse(localStorage.getItem(SELECTED_USER) || JSON.stringify(''))
  );
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
    onSelect(selected);
  }, [hasLoaded]);

  useEffect(() => {
    localStorage.setItem(SELECTED_USER, JSON.stringify(selected));
  }, [selected]);

  const onChange = (newSelected: string) => {
    setSelected(newSelected);
    onSelect(newSelected);
  };

  return (
    <Select
      showSearch
      allowClear
      placeholder="Select a contributor"
      optionFilterProp="children"
      onChange={onChange}
      value={selected}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      style={{ width: '100%' }}
      options={sorted}
    />
  );
}
