import { ApolloProvider } from '@apollo/client';
import React from 'react';

import { githubClient } from '../api/github/apolloClient';
import { useGithubDispatch } from './GithubContext';

export default function ApolloWrappedProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useGithubDispatch();
  return (
    <ApolloProvider client={githubClient(dispatch)}>{children}</ApolloProvider>
  );
}
