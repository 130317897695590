import { LayoutOutlined, LineChartOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import PatModal from '../components/PatModal';
import { useGithub } from '../context/GithubContext';
import useAuth from '../hooks/useAuth';

import Icon from '@ant-design/icons/lib/components/Icon';
import type { MenuProps } from 'antd';
const { Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getLink(label: React.ReactNode, key: React.Key) {
  return <Link to={key.toString()}>{label}</Link>;
}
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,

    children,
    label: getLink(label, key),
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Contributor', '/contributor', <UserOutlined />),
  getItem('Benchmarks', '/benchmarks', <LineChartOutlined />),
  getItem('Aggregations', '/aggregations', <LayoutOutlined />),
];

const AppLayout: React.FC = () => {
  const { patError } = useGithub();
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();

  const isAuthed = useAuth();

  if (!isAuthed) return <SignUpForm />;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <PatModal open={patError} />
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="dark"
      >
        <Menu
          theme="dark"
          style={{borderBottom: '1px solid'}}
          selectedKeys={[]}
          mode="inline"          
          items={[getItem('Git Dive', '/', <Icon component={() => <>🌊</>}/>)]}
        />
        <Menu
          theme="dark"
          mode="inline"          
          items={items}
          selectedKeys={[pathname]}
        />
        <Menu
          theme="dark"
          mode="inline"          
          items={[getItem('Settings', '/settings', <SettingOutlined />)]}
          selectedKeys={[pathname]}
        />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: '0 16px' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
    </Layout>
  );
};

const SignUpForm: React.FC = () => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeGRs5P03MhDWVDBLnE3wx_w9Kr1TFqUVTlMtkbjPZq958bMg/viewform?embedded=true"
      width="640"
      height="1200"
    >
      Loading…
    </iframe>
  );
};
export default AppLayout;
