import { ReadOutlined } from '@ant-design/icons';
import { IssueState } from '@octokit/graphql-schema';
import { Button, Popover, Typography } from 'antd';
import moment from 'moment';
import React from 'react';

import IssueTag from '../../components/IssueTag';
import { ColData } from './types';

export const PR_COLUMNS = [
  {
    title: 'Updated',
    dataIndex: 'updatedAt',
    render: (text: string) => {
      return (
        <Typography.Text
          style={{
            color: moment(text).add(1, 'day').day() <= 1 ? '#aaa' : undefined,
          }}
        >
          {moment(text).format('ddd MM/DD')}
        </Typography.Text>
      );
    },
    sorter: (a: ColData, b: ColData) =>
      moment(a.updatedAt).diff(moment(b.updatedAt)),
  },
  {
    title: 'Title',
    dataIndex: 'title',
    ellipses: true,
    render: (text: any, record: any, index: any) => {
      return (
        <Button key={index} href={record.url} type="link" target="_blank">
          {text}
        </Button>
      );
    },
  },
  {
    title: 'Desc',
    dataIndex: 'bodyHTML',
    render: (text: any, record: any, index: any) => {
      return (
        <Popover
          content={
            text ? (
              <div
                style={{ maxHeight: 400, overflow: 'scroll', maxWidth: 800 }}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            ) : (
              <Typography.Text italic>No description provided</Typography.Text>
            )
          }
          title="PR Description"
          trigger="hover"
        >
          <ReadOutlined size={10} />
        </Popover>
      );
    },
  },
  {
    title: 'State',
    dataIndex: 'state',
    render: (state: IssueState) => {
      return <IssueTag state={state} />;
    },
  },
  {
    title: 'Repo',
    dataIndex: 'repoName',
  },
  {
    title: 'Author',
    dataIndex: 'authorLogin',
    sorter: (a: ColData, b: ColData) =>
      a.author?.login.localeCompare(b.author?.login || '') || 0,
  },
  {
    title: 'Comments',
    dataIndex: 'commentsCount',
    sorter: (a: ColData, b: ColData) =>
      (a.commentsCount || 0) - (b.commentsCount || 0),
  },
  {
    title: 'Reviews',
    dataIndex: 'reviewsCount',
    sorter: (a: ColData, b: ColData) =>
      (a.reviews?.totalCount || 0) - (b.reviews?.totalCount || 0),
  },
  {
    title: 'Commits',
    dataIndex: 'commitsCount',
    sorter: (a: ColData, b: ColData) =>
      a.commits.totalCount - b.commits.totalCount,
  },
  {
    title: 'Files',
    dataIndex: 'changedFiles',
    sorter: (a: ColData, b: ColData) => a.changedFiles - b.changedFiles,
  },
  {
    title: 'Additions',
    dataIndex: 'additions',
    sorter: (a: ColData, b: ColData) => a.additions - b.additions,
  },
  {
    title: 'Deletions',
    dataIndex: 'deletions',
    sorter: (a: ColData, b: ColData) => a.deletions - b.deletions,
  },
  {
    title: 'Net Changes',
    dataIndex: 'netChanges',
    sorter: (a: ColData, b: ColData) =>
      (a.netChanges || 0) - (b.netChanges || 0),
  },
  {
    title: 'To Approved',
    dataIndex: 'timeToApproved',
    sorter: (a: ColData, b: ColData) => {
      if (!a.reviews?.nodes || !a?.reviews?.nodes[0]?.submittedAt) {
        return 1;
      }
      if (!b.reviews?.nodes || !b?.reviews?.nodes[0]?.submittedAt) {
        return -1;
      }
      return (
        moment(b.createdAt).diff(moment(b?.reviews?.nodes[0]?.submittedAt)) -
        moment(a.createdAt).diff(moment(a?.reviews?.nodes[0]?.submittedAt))
      );
    },
  },
  {
    title: 'To Merged',
    dataIndex: 'timeToMerge',
    sorter: (a: ColData, b: ColData) => {
      if (!a.mergedAt) {
        return 1;
      }
      if (!b.mergedAt) {
        return -1;
      }
      return (
        moment(b.createdAt).diff(moment(b.mergedAt)) -
        moment(a.createdAt).diff(moment(a.mergedAt))
      );
    },
  },
];

export function getPrColumnsExcluding(colTitles: string[]) {
  return PR_COLUMNS.filter((item) => {
    return colTitles.indexOf(item.title) !== -1;
  });
}
