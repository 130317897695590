import { Row } from './types';

export default [
  {
    title: 'User Login',
    dataIndex: 'login',
  },
  {
    title: 'User Name',
    dataIndex: 'name',
  },
  {
    title: 'PRs',
    dataIndex: 'prs',
    sorter: (a: Row, b: Row) => a.prs - b.prs,
  },
  {
    title: 'Reviews',
    dataIndex: 'reviews',
    sorter: (a: Row, b: Row) => a.reviews - b.reviews,
  },
  {
    title: 'Commits',
    dataIndex: 'commits',
    sorter: (a: Row, b: Row) => a.commits - b.commits,
  },
  {
    title: 'Issues',
    dataIndex: 'issues',
    sorter: (a: Row, b: Row) => a.issues - b.issues,
  },
];
