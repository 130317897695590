import { createBrowserRouter, Navigate } from 'react-router-dom';

import React from 'react';
import AppLayout from '../layout/AppLayout';
import Aggregations from '../pages/Aggregations';
import Benchmarks from '../pages/Benchmarks';
import ContributorPage from '../pages/Contributor/ContributorPage';
import { SettingsPage } from '../pages/Settings';

export default createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: '/*',
        element: <Navigate to="/contributor" replace={true} />,
      },
      {
        path: 'contributor',
        element: <ContributorPage />,
      },
      {
        path: 'benchmarks',
        index: true,
        element: <Benchmarks />,
      },
      {
        path: 'aggregations',
        element: <Aggregations />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ],
  },
]);
