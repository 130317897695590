import { DownloadOutlined, GithubFilled } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  PullRequest,
  SearchPrsQuery,
  useSearchPrsLazyQuery,
} from '../../graphql/generated-types';
import { getPrQuery } from '../../helpers/getPrQuery';
import { getTimeDiff } from '../../helpers/getTimeDiff';
import toGitHubSearchUrl from '../../helpers/toGitHubSearchUrl';
import { PrQueryRequest } from '../../types';
import { PR_COLUMNS } from './PR_COLUMNS';
import { ColData } from './types';

const PAGE_SIZE = 25;

export default function PRs({
  user,
  from,
  to,
  org,
}: PrQueryRequest) {
  const [fetchPrs, { data, loading, fetchMore }] = useSearchPrsLazyQuery({
    variables: {
      first: 10,
      query: getPrQuery({ user, from, to, org}),
    },
  });

  useEffect(() => {
    if (user) {
      fetchPrs({
        variables: {
          first: PAGE_SIZE,
          query: getPrQuery({ user, from, to, org }),
        },
      });
    }
  }, [user, from, to]);

  useEffect(() => {
    if (!loading && data?.search.pageInfo.hasNextPage) {
      fetchMore({
        variables: { after: data?.search.pageInfo.endCursor },
      });
    }
  }, [loading, data?.search.pageInfo.startCursor]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Button 
        key={user} 
        href={toGitHubSearchUrl(getPrQuery({ user, from, to, org}))} 
        icon={<GithubFilled />}
        size='small'
        target='_blank'
      >
        Open in GitHub
      </Button>
      <Table
        pagination={{
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
        bordered
        loading={loading}
        dataSource={getDataSource(data)}
        columns={PR_COLUMNS.filter((item) => item.title !== 'Author')}
        size="small"
        scroll={{ x: 1200 }}
        rowClassName={(record: PullRequest) => {
          const variation = (moment(record.updatedAt).week() % 2) + 1;
          return `gray-${variation}`;
        }}
      />
      <CSVLink
        filename={`prs-${user}-${from.format("YYYY-MM-DD")}-to-${to.format("YYYY-MM-DD")}.csv`}
        
        data={getPrCsv(data)}
      >
        <Button type="primary" icon={<DownloadOutlined />}>
          Export to CSV
        </Button>
      </CSVLink>
    </Space>
  );
}

function getDataSource(data?: SearchPrsQuery): ColData[] {
  const prs = data?.search.nodes as PullRequest[];
  if (!prs) {
    return [];
  }

  return prs.map((pr: PullRequest) => {
    return {
      ...pr,
      key: pr?.id,
      commentsCount: pr?.comments.totalCount,
      commitsCount: pr?.commits.totalCount,
      reviewsCount: pr?.reviews?.totalCount,
      hasDescription: !!pr?.bodyHTML,
      repoName: pr?.repository.name,
      netChanges: pr?.additions - pr?.deletions,
      timeToMerge: getTimeDiff(pr?.createdAt, pr?.mergedAt),
      timeToApproved: getTimeDiff(
        pr?.createdAt,
        pr?.reviews?.nodes && pr?.reviews?.nodes[0]?.submittedAt
      ),
    };
  });
}

function getPrCsv(query?: SearchPrsQuery) {

  const prs = query?.search.nodes as PullRequest[];
  if (!prs) {
    return [];
  }

  const headers = [
    'id',
    'author',
    'authorName',
    'title',
    'url',
    'state',
    'repo',
    'createdAt',
    'updatedAt',
    'firstReviewedAt',
    'mergedAt',
    'closedAt',
    'commentCount',
    'commitCount',
    'reviewCount',
    'changedFiles',
    'additions',
    'deletions',
    'netChanges',
  ];

  const data = prs.map((pr) => {
    return [
      pr.id,
      pr.author?.login,
      // @ts-expect-error This should work, just haven't figured out how to include the User instead of Actor in types
      pr.author?.name, 
      pr.title,
      pr.url,
      pr.state,
      pr.repository.name, 
      pr.createdAt,
      pr.updatedAt,
      pr.reviews?.nodes && pr.reviews?.nodes[0]?.submittedAt, 
      pr.mergedAt,
      pr.closedAt,
      pr.comments.totalCount, 
      pr.commits.totalCount, 
      pr.reviews?.totalCount, 
      pr.changedFiles,
      pr.additions,
      pr.deletions,
      pr.additions - pr.deletions, 
    ];
  });

  return [headers, ...data];
}

