import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { OrganizationMemberConnection } from '@octokit/graphql-schema';
import { onError } from 'apollo-link-error';
import React from 'react';

import { SearchResultItemConnection } from '../../graphql/generated-types';
import { Action } from '../../reducers/GithubReducer';
import { getPat } from './personalAccessToken';

const ghToken = await getPat();

const cache = new InMemoryCache({
  possibleTypes: {
    User: ['UserDetails'],
  },
  typePolicies: {
    Organization: {
      keyFields: ['login'],
      fields: {
        membersWithRole: {
          keyArgs: false,
          merge(
            existing: OrganizationMemberConnection,
            incoming: OrganizationMemberConnection
          ) {
            return {
              ...incoming,
              nodes: [...(existing?.nodes ?? []), ...(incoming.nodes ?? [])],
            };
          },
        },
      },
    },
    Query: {
      fields: {
        search: {
          keyArgs: ['query'],
          merge(
            existing: SearchResultItemConnection,
            incoming: SearchResultItemConnection
          ) {
            return {
              ...incoming,
              nodes: [...(existing?.nodes ?? []), ...(incoming.nodes ?? [])],
            };
          },
        },
      },
    },
    PullRequest: {
      keyFields: ['id'],
    },
    User: {
      keyFields: ['login'],
    },
  },
});

const retryLink = new RetryLink({
  delay: {
    initial: 5000,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      return !!error && error.statusCode !== 401;
    },
  },
});

const httpLink = new HttpLink({
  uri: 'https://api.github.com/graphql',
  headers: {
    Authorization: `bearer ${ghToken}`,
  },
});

const errorLink = (dispatch: React.Dispatch<Action>) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) {
      if (networkError?.message.includes('401')) {
        dispatch({ type: 'PAT_ERROR' });
      }

      console.log(`[Network error]: ${networkError}`);
    }
  });

export const githubClient = (dispatch: React.Dispatch<Action>) =>
  new ApolloClient({
    cache: cache,
    link: from([errorLink(dispatch), retryLink, httpLink] as ApolloLink[]),
  });
