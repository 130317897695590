import './App.less';

import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { ConfigProvider, theme } from 'antd';
import ApolloWrappedProvider from './context/ApolloWrappedProvider';
import { GithubProvider } from './context/GithubContext';
import reactRouter from './core/reactRouter';

function App() {
  return (
    <React.StrictMode>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <GithubProvider>
          <ApolloWrappedProvider>
            <RouterProvider router={reactRouter} />
          </ApolloWrappedProvider>
        </GithubProvider>
      </ConfigProvider>

    </React.StrictMode>
  );
}

export default App;
