import { Button, DatePicker, Radio, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UserPicker from '../../components/UserPicker';
import { useGithub } from '../../context/GithubContext';
import { getUserLabel } from '../../reducers/GithubReducer';
import PRs from './PRs';
import Reviews from './Reviews';
import { View } from './types';

// moment.updateLocale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s ago',
//     s: 'secs',
//     ss: '%ds',
//     m: '1min',
//     mm: '%dmin',
//     h: '1hr',
//     hh: '%dhr',
//     d: '1d',
//     dd: '%dd',
//     w: '1wk',
//     ww: '%dwk',
//     M: '1mo',
//     MM: '%dmo',
//     y: '1yr',
//     yy: '%dyr',
//   },
// });

export default function ContributorPage() {
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [view, setView] = useState<View>(View.PRs);
  const [from, setFrom] = useState(dayjs().startOf('week'));
  const [to, setTo] = useState(dayjs());
  const githubState = useGithub();

  const isNotSetUp = githubState.organization === undefined;

  useEffect(() => {
    if (isNotSetUp) {
      navigate('/settings');
    }
  }, [githubState.organization, navigate]);

  if (isNotSetUp) {
    return null;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Space size="large">
        <DatePicker.RangePicker
          size="large"
          onChange={(range) => {
            if (range && range[0] && range[1]) {
              setFrom(range[0]);
              setTo(range[1]);
            }
          }}
          value={[from, to]}
        />
        <Space size="small">
          <Button
            size="small"
            onClick={() => {
              setFrom(dayjs().startOf('week'));
              setTo(dayjs());
            }}
            type={
              from.isSame(dayjs().startOf('week'), 'day') &&
              to.isSame(dayjs(), 'day')
                ? 'primary'
                : 'default'
            }
          >
            This Week
          </Button>
          <Button
            size="small"
            onClick={() => {
              setFrom(dayjs().startOf('week').subtract(7, 'days'));
              setTo(dayjs());
            }}
            type={
              from.isSame(dayjs().startOf('week').subtract(7, 'days'), 'day') &&
              to.isSame(dayjs(), 'day')
                ? 'primary'
                : 'default'
            }
          >
            Past 2 Weeks
          </Button>
          <Button
            size="small"
            onClick={() => {
              setFrom(dayjs().subtract(30, 'days'));
              setTo(dayjs());
            }}
            type={
              from.isSame(dayjs().subtract(30, 'days'), 'day') &&
              to.isSame(dayjs(), 'day')
                ? 'primary'
                : 'default'
            }
          >
            30d
          </Button>
          <Button
            size="small"
            onClick={() => {
              setFrom(dayjs().subtract(90, 'days'));
              setTo(dayjs());
            }}
            type={
              from.isSame(dayjs().subtract(90, 'days'), 'day') &&
              to.isSame(dayjs(), 'day')
                ? 'primary'
                : 'default'
            }
          >
            90d
          </Button>
        </Space>
      </Space>

      <UserPicker
        options={Object.values(githubState.users).map((user) => {
          return {
            value: user.login,
            label: getUserLabel(user),
          };
        })}
        onSelect={setSelectedUser}
      />

      <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
        <Radio.Button value={View.PRs}>{View.PRs}</Radio.Button>
        <Radio.Button value={View.Reviews}>{View.Reviews}</Radio.Button>
      </Radio.Group>

      {view === View.PRs && (
        <PRs
          user={selectedUser}
          from={from}
          to={to}
          org={githubState.organization!.login}
        />
      )}
      {view === View.Reviews && (
        <Reviews
          user={selectedUser}
          from={from}
          to={to}
          org={githubState.organization!.login}
        />
      )}
    </Space>
  );
}
