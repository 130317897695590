import React, { createContext, Dispatch, ReactNode, useContext } from 'react';

import { usePersistedReducer } from '../hooks/usePersistedReducer';
import { githubReducer, initialState, Action } from '../reducers/GithubReducer';

export const GithubContext = createContext(initialState);
export const GithubDispatchContext = createContext<Dispatch<Action>>(
  // eslint-disable-next-line comma-dangle
  () => null
);

export function useGithub() {
  return useContext(GithubContext);
}

export function useGithubDispatch() {
  return useContext(GithubDispatchContext);
}

export const GithubProvider = ({ children }: { children: ReactNode }) => {
  const { state, dispatch } = usePersistedReducer(
    githubReducer,
    initialState,
    'GITHUB_STATE'
  );

  return (
    <GithubContext.Provider value={state}>
      <GithubDispatchContext.Provider value={dispatch}>
        {children}
      </GithubDispatchContext.Provider>
    </GithubContext.Provider>
  );
};
