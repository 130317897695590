import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Table } from 'antd';
import React from 'react';
import { CSVLink } from 'react-csv';

import { useGithub } from '../../context/GithubContext';
import { User } from '../../reducers/GithubReducer';
import HistoricalPrs from './HistoricalPrs';
import TABLE_COLS from './TABLE_COLS';
import { Row } from './types';

export default function Aggregations() {
  const githubState = useGithub();
  const PAGE_SIZE = 15;
  
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Table
        pagination={{
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
        bordered
        dataSource={getDataSource(githubState.users)}
        columns={TABLE_COLS}
        size="small"
      />
      <CSVLink
        filename={'git-dive-export.csv'}
        headers={['login', 'name', 'prs', 'reviews', 'commits', 'issues']}
        data={getDataSource(githubState.users)}
      >
        <Button type="primary" icon={<DownloadOutlined />}>
          Export to CSV
        </Button>
      </CSVLink>
      <Divider />
      <HistoricalPrs/>
    </Space>
  );
}
function getDataSource(users: { [key: string]: User }): Row[] {
  return Object.keys(users).map((login) => {
    const contributions = users[login].contributionsCollection;
    const totals = Object.values(contributions).reduce(
      (agg, val) => {
        return {
          commits: agg.commits + val.totalCommitContributions,
          prs: agg.prs + val.totalPullRequestContributions,
          reviews: agg.reviews + val.totalPullRequestReviewContributions,
          issues: agg.issues + val.totalIssueContributions,
        };
      },
      {
        commits: 0,
        prs: 0,
        reviews: 0,
        issues: 0,
      }
    );

    return {
      key: login,
      login,
      name: users[login].name,
      prs: totals.prs,
      commits: totals.commits,
      reviews: totals.reviews,
      issues: totals.issues,
    };
  });
}
