import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

const SELECTED_USERS = 'UserPicker:selectedUsers';

export default function UsersPicker({
  options,
  onSelect,
}: {
  options?: { value: string; label: string }[];
  onSelect: (userLogins: string[]) => void;
}) {
  const sorted = options?.sort((a, b) => a.value.localeCompare(b.value));
  const [selected, setSelected] = useState<string[]>(
    JSON.parse(localStorage.getItem(SELECTED_USERS) || JSON.stringify([]))
  );
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
    onSelect(selected);
  }, [hasLoaded]);

  useEffect(() => {
    localStorage.setItem(SELECTED_USERS, JSON.stringify(selected));
  }, [selected]);

  const onChange = (newSelected: string[]) => {
    setSelected(newSelected);
    onSelect(newSelected);
  };

  return (
    <Select
      showSearch
      allowClear
      mode="multiple"
      placeholder="Select a contributor"
      optionFilterProp="children"
      onChange={onChange}
      value={selected}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      style={{ width: '100%' }}
      options={sorted}
    />
  );
}
