import { GithubFilled } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import React, { useState } from 'react';

import { setPat } from '../api/github/personalAccessToken';
import { useGithubDispatch } from '../context/GithubContext';
import { useLoginLazyQuery } from '../graphql/generated-types';

export default function PatModal(props: ModalProps) {
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(props.open);
  const dispatch = useGithubDispatch();

  const [fetchLogin] = useLoginLazyQuery();

  const onSubmit = () => {
    setPat(token)
      .then(() => {
        return fetchLogin();
      })
      .then((res) => {
        if (!res.error?.message.includes('401')) {
          setOpen(false);
          dispatch({
            type: 'PAT_ERROR_RESOLVED',
          });
        }
      });
  };

  return (
    <Modal
      title={'Github Personal Access Token'}
      onOk={onSubmit}
      {...props}
      open={open}
      closable={false}
      footer={[
        <Button key="submit" type="primary" onClick={onSubmit}>
          Submit
        </Button>,
      ]}
    >
      <>
        <Typography.Paragraph>
          This application requires access to GitHub Data.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Please generate a new Github Personal Access Token (classic).
        </Typography.Paragraph>
        <Button
          type="primary"
          href="https://github.com/settings/tokens"
          target="_blank"
          icon={<GithubFilled />}
          style={{ marginBottom: 15 }}
        >
          Open Github
        </Button>
        <Typography.Paragraph>
          Select scopes: <code>repo</code>,<code>read:org</code>,{' '}
          <code>user</code>
        </Typography.Paragraph>

        <Input
          placeholder="Paste your personal access token"
          onChange={(e) => setToken(e.target.value)}
          onPressEnter={onSubmit}
        />
        <Typography.Paragraph italic type="secondary" style={{ marginTop: 10 }}>
          (Tokens are only stored locally in your browser.)
        </Typography.Paragraph>
      </>
    </Modal>
  );
}
