import { CaretRightOutlined, GithubFilled } from "@ant-design/icons";
import { Button, Collapse, Space } from "antd";
import React, { useState } from "react";
import { useGithub } from "../../context/GithubContext";
import { getUserLabel } from "../../reducers/GithubReducer";

export default function GitHubProfileLinks({logins}:{ logins: string[]} /** gh user logins */) {
  const { users } = useGithub();
  const [isActive, setIsActive] = useState(false);

  return (
    <Collapse
      bordered={false}
      activeKey={isActive ? 1 : 0}
      onChange={() => setIsActive(!isActive)}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
    >
      <Collapse.Panel header="GitHub Profiles" key={1}>
        <Space>
        {logins.map(login => {
          return (
            <Button 
              key={login} 
              href={`https://github.com/${login}`} 
              icon={<GithubFilled />}
              size='small'
              target='_blank'
            >
              {getUserLabel(users[login])} 
            </Button>
          )
        })}
        </Space>
      </Collapse.Panel>
    </Collapse>
  )
}
