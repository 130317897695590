import { GithubFilled } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { Dayjs } from 'dayjs';
import React, { useEffect } from 'react';

import {
  PullRequest,
  SearchPrsQuery,
  useSearchPrsLazyQuery,
} from '../../graphql/generated-types';
import { getReviewsQuery } from '../../helpers/getReviewsQuery';
import { getTimeDiff } from '../../helpers/getTimeDiff';
import toGitHubSearchUrl from '../../helpers/toGitHubSearchUrl';
import { PR_COLUMNS } from './PR_COLUMNS';
import { ColData } from './types';

const PAGE_SIZE = 20;

export default function Reviews({
  user,
  org,
  from,
  to,
}: {
  user: string;
  org: string;
  from: Dayjs;
  to: Dayjs;
}) {
  const [fetchPrs, { data, loading, fetchMore }] = useSearchPrsLazyQuery(
    {
      variables: {
        first: 10,
        query: getReviewsQuery({ user, from, to, org }),
      },
    }
  );

  useEffect(() => {
    if (user) {
      fetchPrs({
        variables: {
          first: PAGE_SIZE,
          query: getReviewsQuery({ user, from, to, org }),
        },
      });
    }
  }, [user, from, to]);

  useEffect(() => {
    if (!loading && data?.search.pageInfo.hasNextPage) {
      fetchMore({
        variables: { after: data?.search.pageInfo.endCursor },
      });
    }
  }, [loading, data?.search.pageInfo.startCursor]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Button 
        key={user} 
        href={toGitHubSearchUrl(getReviewsQuery({ user, from, to, org}))} 
        icon={<GithubFilled />}
        size='small'
        target='_blank'
      >
        Open in GitHub
      </Button>
      <Table
        pagination={{
          pageSize: PAGE_SIZE,
          showSizeChanger: false,
        }}
        bordered
        loading={loading}
        dataSource={getDataSource(data)}
        columns={PR_COLUMNS}
        size="small"
        scroll={{ x: 1200 }}
      />
    </Space>
  );
}

function getDataSource(query?: SearchPrsQuery): ColData[] {
  const prs = query?.search.nodes as PullRequest[];
  if (!prs) {
    return [];
  }

  return prs.map((pr: PullRequest) => {
    return {
      ...pr,
      key: pr?.id,
      authorLogin: pr?.author?.login,
      commentsCount: pr?.comments.totalCount,
      commitsCount: pr?.commits.totalCount,
      reviewsCount: pr?.reviews?.totalCount,
      hasDescription: !!pr?.bodyHTML,
      repoName: pr?.repository.name,
      netChanges: pr?.additions - pr?.deletions,
      timeToMerge: getTimeDiff(pr?.createdAt, pr?.mergedAt),
      timeToApproved: getTimeDiff(
        pr?.createdAt,
        pr?.reviews?.nodes && pr?.reviews?.nodes[0]?.submittedAt
      ),
    };
  });
}


