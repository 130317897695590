import { ApartmentOutlined } from '@ant-design/icons';
import { Button, Input, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';

import { useGithub, useGithubDispatch } from '../../context/GithubContext';
import {
  Organization,
  OrganizationQuery,
  useOrganizationLazyQuery,
} from '../../graphql/generated-types';

export default function OrganizationSelector() {
  const { organization } = useGithub();
  const dispatch = useGithubDispatch();
  const [organizationLogin, setOrganizationLogin] = useState('');
  const [fetchOrganization, { data, fetchMore }] = useOrganizationLazyQuery({
    variables: {
      organization: organizationLogin,
      first: 100,
    },
  });

  useEffect(() => {
    if (organization) {
      setOrganizationLogin(organization.login);
    }
  }, [organization]);

  useEffect(() => {
    if (data) {
      const hasNextPage = getHasNextPage(data);
      const after = getAfter(data);

      if (hasNextPage && after !== null) {
        fetchMore({ variables: { after } });
      } else {
        if (data.organization !== null) {
          dispatch({
            type: 'UPDATE_ORGANIZATION',
            data: data.organization as Organization,
          });
          dispatch({
            type: "CALCULATE_AGGREGATIONS",
          })
        }
      }
    }
  }, [data]);

  return (
    <Space direction="vertical">
      <Typography.Title level={4}>Organization</Typography.Title>
      <Space>
        <Input
          placeholder="Enter your organization login"
          prefix={<ApartmentOutlined />}
          value={organizationLogin}
          onChange={(evt) =>
            setOrganizationLogin(evt.target.value.replace(/\s/, ''))
          }
          onPressEnter={() => fetchOrganization()}
        />
        <Button onClick={() => fetchOrganization()}>Submit</Button>
      </Space>
      {organization && (
        <Typography.Paragraph>
          {`Fetched ${
            organization.membersWithRole.nodes?.length
          } users for organization ${organization.name || organization.login}.`}
        </Typography.Paragraph>
      )}
    </Space>
  );
}

function getHasNextPage(data?: OrganizationQuery) {
  return data?.organization?.membersWithRole.pageInfo.hasNextPage;
}

function getAfter(data?: OrganizationQuery) {
  return data?.organization?.membersWithRole.pageInfo.endCursor;
}
