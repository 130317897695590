import { DownloadOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';

import UsersPicker from '../../components/UsersPicker';
import { useGithub } from '../../context/GithubContext';
import { getUserLabel, State, User } from '../../reducers/GithubReducer';
import ContributionsGraph, { Type } from './ContributionsGraph';
import GitHubProfileLinks from './GitHubProfileLinks';

export default function BenchmarksPage() {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const githubState = useGithub();
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <UsersPicker
        options={Object.values(githubState.users).map((user) => {
          return {
            value: user.login,
            label: getUserLabel(user),
          };
        })}
        onSelect={setSelectedUsers}
      />
      <GitHubProfileLinks logins={selectedUsers} />
      <ContributionsGraph selectedUsers={selectedUsers} type={Type.Prs} />
      <CSVLink
        filename={`prs-${githubState.organization?.login}-${getLastDateFetched(
          githubState
        )}.csv`}
        data={getPrCsv({
          users: githubState.users,
          dates: githubState.datesFetched,
        })}
      >
        <Button size="small" icon={<DownloadOutlined />}>
          Export to CSV
        </Button>
      </CSVLink>
      <ContributionsGraph selectedUsers={selectedUsers} type={Type.PrReviews} />
      <ContributionsGraph selectedUsers={selectedUsers} type={Type.Commits} />
      <ContributionsGraph selectedUsers={selectedUsers} type={Type.Issues} />
    </Space>
  );
}

function getLastDateFetched(githubState: State) {
  return Object.keys(githubState.datesFetched).sort((a, b) =>
    b.localeCompare(a)
  )[0];
}

function getPrCsv({
  dates,
  users,
}: {
  dates: { [key: string]: boolean };
  users: { [key: string]: User };
}) {
  const headerDates = Object.keys(dates)
    .reduce<string[]>((agg, date) => {
      if (dates[date]) agg.push(date);
      return agg;
    }, [])
    .sort((a, b) => b.localeCompare(a)); // reverse order

  const headers = ['login', 'name', ...headerDates];

  const data = Object.values(users).map((user) => {
    const contributions = headerDates.map((date) => {
      return (
        user.contributionsCollection[date]?.totalPullRequestContributions || ''
      );
    });
    return [user.login, user.name || '', ...contributions];
  });

  return [headers, ...data];
}
