import { useQuery } from '@apollo/client';
import { Button, Progress, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useGithub, useGithubDispatch } from '../../context/GithubContext';
import {
  OrganizationMemberConnection,
  UserDocument,
} from '../../graphql/generated-types';

const USER_BATCH_SIZE = 25;
const MAX_WEEKS = 12;

export default function ContributionsCache({
  organizationId,
  users,
}: {
  organizationId?: string;
  users?: OrganizationMemberConnection;
}) {
  const githubState = useGithub();
  const dispatch = useGithubDispatch();

  const [rebuildingCache, setRebuildingCache] = useState(false);
  const [timeStarted, setTimeStarted] = useState<moment.Moment | undefined>();
  const [userPage, setUserPage] = useState(0);
  const [weekPage, setWeekPage] = useState(0);

  const { fetchMore, loading } = useQuery(UserDocument, {
    variables: {
      userIds: [users?.nodes?.slice(0, 1)],
      from: getWeekISO(weekPage + 1),
      to: getWeekISO(weekPage),
      organizationID: organizationId!,
    },
  });

  const getProgressPercent = () => {
    const totalUsers = users?.nodes?.length;
    if (!totalUsers) return 100;

    const totalTicks = (totalUsers / USER_BATCH_SIZE) * MAX_WEEKS;
    const currentTicks = userPage + weekPage * (totalUsers / USER_BATCH_SIZE);
    return Math.round((currentTicks / totalTicks) * 100);
  };

  const getEta = () => {
    moment().diff(timeStarted);
    return moment().to(
      moment().add(
        (moment().diff(timeStarted) * 100) / getProgressPercent(),
        'milliseconds'
      ),
      true
    );
  };

  useEffect(() => {
    if (users && users.nodes && organizationId && rebuildingCache && !loading) {
      if (weekPage < MAX_WEEKS) {
        if (userPage * USER_BATCH_SIZE < users.nodes.length) {
          const isWeekCached = githubState.datesFetched[getWeekDate(weekPage)];
          if (isWeekCached) {
            setWeekPage(weekPage + 1);
            return;
          }
          const userIdsBatch = users?.nodes
            ?.map((userNode) => userNode?.id)
            .slice(
              userPage * USER_BATCH_SIZE,
              (userPage + 1) * USER_BATCH_SIZE
            );

          fetchBatch(
            userIdsBatch,
            getWeekISO(weekPage + 1),
            getWeekISO(weekPage)
          )?.then(() => {
            if ((userPage + 1) * USER_BATCH_SIZE < users!.nodes!.length) {
              setUserPage(userPage + 1);
            } else {
              console.log('Fetched week:', getWeekDate(weekPage));
              dispatch({
                type: 'WEEK_CACHED',
                week: getWeekDate(weekPage),
              });
              dispatch({ type: 'CALCULATE_AGGREGATIONS' });

              setWeekPage(weekPage + 1);
              setUserPage(0);
            }
          });
        }
      } else {
        // Done
        setRebuildingCache(false);
        setTimeStarted(undefined);
        dispatch({ type: 'CALCULATE_AGGREGATIONS' });
      }
    }
  }, [rebuildingCache, userPage, weekPage, loading]);

  const fetchBatch = (
    userIds: (string | undefined)[] | undefined,
    from: string,
    to: string
  ) => {
    if (!userIds) {
      return;
    }
    return fetchMore({
      variables: {
        from,
        to,
        userIds: userIds,
      },
    }).then((result) =>
      dispatch({ type: 'UPDATE_USERS', result: result.data.nodes })
    );
  };

  const renderProgress = () => {
    if (!rebuildingCache) return;

    return (
      <div>
        {`Fetching ${MAX_WEEKS} weeks of data for ${users?.nodes?.length} users...`}
        <Spin size="small" style={{ marginLeft: 10 }} />
        <Progress percent={getProgressPercent()} />
        {`ETA: ${getEta()}`}
      </div>
    );
  };

  return (
    <Space direction="vertical">
      <Typography.Title level={4}>Contributions Cache</Typography.Title>
      <Space direction="vertical" size="small">
        <Button
          onClick={() => {
            setRebuildingCache(true);
            setTimeStarted(moment());
            setUserPage(0);
            setWeekPage(0);
          }}
          disabled={!organizationId || !users || rebuildingCache}
        >
          Update Cache
        </Button>
        {renderProgress()}
        <Button onClick={() => dispatch({ type: 'CALCULATE_AGGREGATIONS' })}>
          Calculate Aggregations
        </Button>
        <Typography.Paragraph>
          {`Fetched data for ${
            Object.keys(githubState.users).length
          } users for the following weeks: ${Object.keys(
            githubState.datesFetched
          )
            .sort((a, b) => a.localeCompare(b))
            .join(', ')}.`}
        </Typography.Paragraph>
        <Button
          danger
          size="small"
          onClick={() => {
            dispatch({ type: 'RESET_STATE' });
          }}
          disabled={!organizationId || !users || rebuildingCache}
        >
          Wipe Local Cache
        </Button>
      </Space>
    </Space>
  );
}

function getWeekISO(weeksSubtracted: number) {
  return moment()
    .startOf('week')
    .subtract(weeksSubtracted, 'weeks')
    .toISOString();
}

function getWeekDate(weeksSubtracted: number) {
  return moment()
    .startOf('week')
    .subtract(weeksSubtracted, 'weeks')
    .format('YYYY-MM-DD');
}
