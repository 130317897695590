import { PullRequest } from '../../graphql/generated-types';

export enum View {
  PRs = 'PRs',
  Reviews = 'Reviews',
}

export type ColData = PullRequest & {
  key: string;
  commentsCount?: number;
  commitsCount?: number;
  reviewsCount?: number;
  netChanges?: number;
  hasDescription: boolean;
  repoName: string;
  timeToMerge?: string;
  timeToApproved?: string;
  authorLogin?: string;
};
