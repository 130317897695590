import { Space } from 'antd';
import React from 'react';
import { useGithub } from '../../context/GithubContext';
import OrganizationSelector from './OrganizationSelector';
import ContributionsCache from './ContributionsCache';

export function SettingsPage() {
  const { organization } = useGithub();
  return (
    <Space direction="vertical" size="large">
      <OrganizationSelector />
      <ContributionsCache
        organizationId={organization?.id}
        users={organization?.membersWithRole}
      />
    </Space>
  );
}
