import * as Colors from '@ant-design/colors';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import Typography from 'antd/lib/typography';
import React, { useCallback, useRef, useState } from 'react';
import { useGithub } from '../../context/GithubContext';
import { MetricType } from '../../reducers/GithubReducer';

import { Button, Space, Tooltip } from 'antd';

export enum Type {
  Prs = 'totalPullRequestContributions',
  PrReviews = 'totalPullRequestReviewContributions',
  Commits = 'totalCommitContributions',
  Issues = 'totalIssueContributions',
}

const TypeLabels = {
  [Type.Prs]: 'Pull Requests',
  [Type.PrReviews]: 'Pull Request Reviews',
  [Type.Commits]: 'Commits',
  [Type.Issues]: 'Issues',
};

const ColorsForAggregates = {
  [MetricType.Mean]: Colors.grey[9],
  [MetricType.Ninetieth]: Colors.grey[3],
  [MetricType.NinetyFifth]: Colors.grey[0],
};

const COLORS = [
  Colors.blue[4],
  Colors.volcano[5],
  Colors.green[4],
  Colors.gold[3],
  Colors.purple[4],
  Colors.magenta[2],
];

export default function ContributionsGraph({
  selectedUsers,
  type,
}: {
  selectedUsers: string[];
  type: Type;
}) {
  const ref = useRef();
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const githubState = useGithub();
  const getDataForMetric = useCallback(
    (metric: string) =>
      Object.entries(githubState.aggregations[type])
        .reduce(
          (
            builder: { date: string; value: number; label: string }[],
            [date, data]
          ) => {
            builder.push({
              date,
              value: data[metric],
              label: metric,
            });
            return builder;
          },
          []
        )
        .sort(dateComparator),
    [githubState.aggregations[type]]
  );

  const metrics = [];
  for (const metric in MetricType) {
    metrics.push(
      getDataForMetric(MetricType[metric as keyof typeof MetricType])
    );
  }

  const getData = (login: string) => {
    return Object.entries(githubState.users[login].contributionsCollection)
      .reduce(
        (
          builder: { date: string; value: number; label: string }[],
          [date, contributions]
        ) => {
          builder.push({
            date,
            value: contributions[type],
            label: login,
          });
          return builder;
        },
        []
      )
      .sort(dateComparator);
  };

  const isUser = (label: string) => {
    return !!githubState.users[label];
  };

  const userData = selectedUsers.flatMap((user) => {
    return getData(user);
  });

  const getColorForUser = (label: string) => {
    return COLORS[selectedUsers.indexOf(label) % COLORS.length];
  };

  const getLineForUser = (label: string) => {
    const multiple = Math.floor(
      selectedUsers.indexOf(githubState.loginToUserId[label]) / COLORS.length
    );
    return {
      lineDash: [
        Math.abs(20 / Math.sqrt(multiple + 1)),
        7 * Math.sqrt(multiple),
      ],
      opacity: 1 / Math.sqrt(multiple + 1),
    };
  };

  const config = {
    data: [...userData, ...metrics.flatMap((arr) => arr)],
    xField: 'date',
    yField: 'value',
    seriesField: 'label',
    smooth: false,
    autoFit: true,
    tooltip: { title: TypeLabels[type] },
    lineStyle: ({ label }: { label: string }) => {
      return !isUser(label)
        ? { lineDash: [0, 7], opacity: 0.8 }
        : getLineForUser(label);
    },
    color: (datum: any ) => {
      return Object.values(MetricType).includes(datum.label)
        ? ColorsForAggregates[datum.label as MetricType] || Colors.grey[5]
        : datum.label === 'std'
        ? Colors.grey[5]
        : getColorForUser(datum.label);
    },
  };

  return (
    <>
      <Space align="baseline">
        <Typography.Title level={4}>{TypeLabels[type]}</Typography.Title>

        <Tooltip title={`${isZoomedIn ? 'Collapse' : 'Enlarge'} Chart`}>
          <Button
            size="small"
            type="link"
            icon={isZoomedIn ? <ZoomOutOutlined /> : <ZoomInOutlined />}
            onClick={() => setIsZoomedIn(!isZoomedIn)}
          />
        </Tooltip>
      </Space>
      <Line
        style={{ height: isZoomedIn ? 600 : 200 }}
        {...config}
        chartRef={ref}
      />
    </>
  );
}

function dateComparator(
  a: { date: string; value: number; label: string },
  b: { date: string; value: number; label: string }
) {
  return a.date > b.date ? 1 : -1;
}
