import * as mathjs from 'mathjs';
import moment from 'moment';

import {
  Organization,
  UserWithContributionsFragment,
} from '../graphql/generated-types';

export enum MetricType {
  NinetyFifth = '95th',
  Ninetieth = '90th',
  Mean = 'Mean',
}

export type User = {
  login: string;
  id: string;
  name?: string | null;
  contributionsCollection: {
    [key: string]: {
      endedAt: string;
      totalPullRequestReviewContributions: number;
      totalPullRequestContributions: number;
      totalCommitContributions: number;
      totalIssueContributions: number;
    };
  };
};

export function getUserLabel(user: User) {
  return user.name ? `${user.login} (${user.name})` : user.login;
}

export type State = {
  patError: boolean;
  organization?: Organization;
  users: {
    [key: string]: User;
  };
  userIdToLogin: {
    [key: string]: string;
  };
  loginToUserId: {
    [key: string]: string;
  };
  datesFetched: { [key: string]: boolean };
  aggregations: {
    totalPullRequestContributions: {
      [key: string]: Record<string, number>;
    };
    totalPullRequestReviewContributions: {
      [key: string]: Record<string, number>;
    };
    totalCommitContributions: {
      [key: string]: Record<string, number>;
    };
    totalIssueContributions: {
      [key: string]: Record<string, number>;
    };
  };
};

export const initialState: State = {
  users: {},
  userIdToLogin: {},
  loginToUserId: {},
  datesFetched: {},
  aggregations: {
    totalPullRequestContributions: {},
    totalPullRequestReviewContributions: {},
    totalCommitContributions: {},
    totalIssueContributions: {},
  },
  patError: false,
};

export type Action =
  | { type: 'UPDATE_ORGANIZATION'; data?: Organization }
  | {
      type: 'UPDATE_USERS';
      result: UserWithContributionsFragment[];
    }
  | {
      type: 'CALCULATE_AGGREGATIONS';
    }
  | {
      type: 'PAT_ERROR';
    }
  | { type: 'PAT_ERROR_RESOLVED' }
  | {
      type: 'WEEK_CACHED';
      week: string;
    }
  | {
      type: 'RESET_STATE';
    };

export const githubReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_USERS': {
      const newState = { ...state };
      action.result.forEach((user) => {
        if (user === null) return;

        const date = moment(user.contributionsCollection.endedAt).format(
          'YYYY-MM-DD'
        );

        const contributionsCollection =
          newState.users[user.login]?.contributionsCollection ?? {};
        contributionsCollection[date] = user.contributionsCollection;

        newState.users[user.login] = {
          ...user,
          contributionsCollection,
        };

        newState.userIdToLogin[user.id] = user.login;
        newState.loginToUserId[user.login] = user.id;
      }, initialState);
      return newState;
    }
    case 'PAT_ERROR': {
      if (state.patError) return state;
      return { ...state, patError: true };
    }
    case 'PAT_ERROR_RESOLVED': {
      if (!state.patError) return state;
      return { ...state, patError: false };
    }
    case 'WEEK_CACHED': {
      return {
        ...state,
        datesFetched: { ...state.datesFetched, [action.week]: true },
      };
    }
    case 'RESET_STATE': {
      return initialState;
    }
    case 'CALCULATE_AGGREGATIONS': {
      const aggregations = Object.values(state.users).reduce(
        (
          builder: {
            totalPullRequestContributions: {
              [key: string]: {
                values: number[];
              };
            };
            totalPullRequestReviewContributions: {
              [key: string]: {
                values: number[];
              };
            };
            totalCommitContributions: {
              [key: string]: {
                values: number[];
              };
            };
            totalIssueContributions: {
              [key: string]: {
                values: number[];
              };
            };
          },
          user
        ) => {
          Object.entries(user.contributionsCollection).forEach(
            ([date, contributions]) => {
              builder.totalPullRequestContributions[date] = {
                values: [
                  ...((builder.totalPullRequestContributions[date] &&
                    builder.totalPullRequestContributions[date].values) ??
                    []),
                  contributions.totalPullRequestContributions,
                ],
              };
              builder.totalPullRequestReviewContributions[date] = {
                values: [
                  ...((builder.totalPullRequestReviewContributions[date] &&
                    builder.totalPullRequestReviewContributions[date].values) ??
                    []),
                  contributions.totalPullRequestReviewContributions,
                ],
              };
              builder.totalCommitContributions[date] = {
                values: [
                  ...((builder.totalCommitContributions[date] &&
                    builder.totalCommitContributions[date].values) ??
                    []),
                  contributions.totalCommitContributions,
                ],
              };
              builder.totalIssueContributions[date] = {
                values: [
                  ...((builder.totalIssueContributions[date] &&
                    builder.totalIssueContributions[date].values) ??
                    []),
                  contributions.totalIssueContributions,
                ],
              };
            }
          );
          return builder;
        },
        {
          totalPullRequestContributions: {},
          totalPullRequestReviewContributions: {},
          totalCommitContributions: {},
          totalIssueContributions: {},
        }
      );

      const totalPullRequestContributions = Object.entries(
        aggregations.totalPullRequestContributions
      ).reduce(
        (builder: any, [date, data]) => reduceMetrics(builder, date, data),
        {}
      );
      const totalPullRequestReviewContributions = Object.entries(
        aggregations.totalPullRequestReviewContributions
      ).reduce(
        (builder: any, [date, data]) => reduceMetrics(builder, date, data),
        {}
      );
      const totalIssueContributions = Object.entries(
        aggregations.totalIssueContributions
      ).reduce(
        (builder: any, [date, data]) => reduceMetrics(builder, date, data),
        {}
      );
      const totalCommitContributions = Object.entries(
        aggregations.totalCommitContributions
      ).reduce(
        (builder: any, [date, data]) => reduceMetrics(builder, date, data),
        {}
      );

      const { userIdToLogin, loginToUserId } = Object.values(
        state.users
      ).reduce(
        (builder: any, user) => {
          builder.userIdToLogin[user.id] = user.login;
          builder.loginToUserId[user.login] = user.id;
          return builder;
        },
        { userIdToLogin: {}, loginToUserId: {} }
      );

      return {
        ...state,
        userIdToLogin,
        loginToUserId,
        aggregations: {
          totalPullRequestContributions,
          totalPullRequestReviewContributions,
          totalCommitContributions,
          totalIssueContributions,
        },
      };
    }

    case 'UPDATE_ORGANIZATION': {
      const users: { [key: string]: User } = {};
      const userIdToLogin: { [key: string]: string } = {};
      const loginToUserId: { [key: string]: string } = {};

      action.data?.membersWithRole.nodes?.forEach((user) => {
        if (user === null) return;

        users[user.login] = state.users[user.login] || {
          ...user,
          contributionsCollection: {},
        };

        userIdToLogin[user.id] = user.login;
        loginToUserId[user.login] = user.id;

        // newState.userIdToLogin[user.id] = user.login;
        // newState.loginToUserId[user.login] = user.id;
      }, initialState);

      return {
        ...state,
        organization: action.data,
        users,
        userIdToLogin,
        loginToUserId,
      };
    }
    default: {
      console.warn('Unhandled action!', action);
      return state;
    }
  }
};

function reduceMetrics(builder: any, date: string, data: any) {
  builder[date] = {
    values: [...data.values],
    [MetricType.Mean]: mathjs.round(mathjs.mean(data.values), 1),
    [MetricType.NinetyFifth]: mathjs.round(
      mathjs.quantileSeq(data.values, 0.95) as number,
      1
    ),
    [MetricType.Ninetieth]: mathjs.round(
      mathjs.quantileSeq(data.values, 0.9) as number,
      1
    ),
  };
  return builder;
}
