import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useGithub } from "../../context/GithubContext";
import { PullRequest, SearchPrsNoHtmlQuery, useSearchPrsNoHtmlLazyQuery } from "../../graphql/generated-types";
import { getPrQuery } from "../../helpers/getPrQuery";

export default function HistoricalPrs() {
  const [from] = useState(dayjs().startOf('week').subtract(1, 'week'));
  const [to] = useState(dayjs().startOf('week'));
  const [prs, setPrs] = useState<PullRequest[]>([]);
  const [index, setIndex] = useState(0);
  const [fetching, setFetching] = useState(false);
  const downloadButtonRef = useRef<HTMLElement>(null);

  const githubState = useGithub();
  const logins = Object.keys(githubState.loginToUserId);

  const [fetchPrs, { data, loading, fetchMore }] = useSearchPrsNoHtmlLazyQuery({
    variables: {
      first: 50,
      query: getPrQuery({ user: logins[index], from, to, org: githubState.organization!.login}),
    },
  });

  const updateState = (data: SearchPrsNoHtmlQuery | undefined) => {  
    if (data?.search.nodes && data.search.nodes.length > 0) {
      setPrs([
        ...prs, 
        ...data?.search.nodes as PullRequest[],
      ])
    }

    if (typeof data === undefined) {
      setFetching(false);
      return;
    }

    const hasNextPage = data!.search.pageInfo.hasNextPage;

    if (!hasNextPage) {
      if (index + 1 >= logins.length) {
        setFetching(false);
        setIndex(0); 
        console.log(downloadButtonRef)
        downloadButtonRef.current?.click();
      } else {
        setIndex(index+1);
      }
    }
  }

  useEffect(() => {
    if (fetching && !loading && index < logins.length) {
      fetchMore({
        variables: { after: data?.search.pageInfo.endCursor, user: logins[index] },
      }).then(() => updateState(data));
    }
  })
  
  const fetchForUser = () => {
    setFetching(true);
    fetchPrs().then(res => updateState(res.data));
  }

  return (
    <Space direction="vertical">
      <Button loading={fetching} onClick={fetchForUser}>Fetch PRs for {from.format("YYYY-MM-DD")} .. {to.format("YYYY-MM-DD")}</Button>
      {fetching ? `${index} of ${logins.length} complete. Fetching ${logins[index]}` : ''}
      {prs.length > 0 && !fetching ? `Fetched ${prs.length} prs ✅` : ``}
      <CSVLink
        filename={`prs-week-ending-${to.format("YYYY-MM-DD")}.csv`}  
        data={getPrCsv(prs)}
      >
        <Button type="primary" icon={<DownloadOutlined />} ref={downloadButtonRef}>
          Export to CSV
        </Button>
      </CSVLink>
    </Space>
  );
}

function getPrCsv(prs: PullRequest[]) {
  const headers = [
    'id',
    'author',
    'authorName',
    'title',
    'url',
    'state',
    'repo',
    'createdAt',
    'updatedAt',
    'firstReviewedAt',
    'mergedAt',
    'closedAt',
    'commentCount',
    'commitCount',
    'reviewCount',
    'changedFiles',
    'additions',
    'deletions',
    'netChanges',
  ];

  const data = prs.map((pr) => {
    return [
      pr.id,
      pr.author?.login,
      // @ts-expect-error This should work, just haven't figured out how to include the User instead of Actor in types
      pr.author?.name, 
      pr.title,
      pr.url,
      pr.state,
      pr.repository.name, 
      pr.createdAt,
      pr.updatedAt,
      pr.reviews?.nodes && pr.reviews?.nodes[0]?.submittedAt, 
      pr.mergedAt,
      pr.closedAt,
      pr.comments.totalCount, 
      pr.commits.totalCount, 
      pr.reviews?.totalCount, 
      pr.changedFiles,
      pr.additions,
      pr.deletions,
      pr.additions - pr.deletions, 
    ];
  });

  return [headers, ...data];
}
